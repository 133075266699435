import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "home",
    component: () => import("../views/home/index.vue"),
  },
  {
    path: "/act",
    name: "act",
    component: () => import("../views/act/index.vue"),
  },
  {
    path: "/help",
    name: "help",
    component: () => import("../views/help/index.vue"),
    redirect: "/help/classify",
    children: [
      {
        path: "/help/classify",
        name: "classify",
        component: () => import("../views/help/components/classify/index.vue"),
      },
      {
        path: "/help/search",
        name: "search",
        component: () => import("../views/help/components/search/index.vue"),
      },
    ],
  },
  {
    path: "/about",
    name: "about",
    component: () => import("../views/about/index.vue"),
    children: [
      {
        path: "/about/company",
        name: "company",
        component: () => import("../views/about/components/company.vue"),
      },
      {
        path: "/about/contact",
        name: "contact",
        component: () => import("../views/about/components/contact.vue"),
      },
      {
        path: "/about/news",
        name: "news",
        component: () => import("../views/about/components/news.vue"),
        children: [
          {
            path: "/about/news/sciencenew",
            name: "sciencenew",
            component: () => import("../views/about/components/sciencenew.vue"),
          },
          {
            path: "/about/news/companynew",
            name: "companynew",
            component: () => import("../views/about/components/companynew.vue"),
          },
        ],
      },
      {
        path: "/about/law",
        name: "law",
        component: () => import("../views/about/components/law.vue"),
      },
      {
        path: "/about/links",
        name: "links",
        component: () => import("../views/about/components/links.vue"),
      },
    ],
  },
  {
    path: "/agreement",
    name: "agreement",
    component: () => import("../views/agreement/index.vue"),
  },
  {
    path: "/login",
    name: "login",
    component: () => import("../views/login/index.vue"),
  },
  {
    path: "/personal",
    name: "personal",
    component: () => import("../views/personal/index.vue"),
    children: [
      {
        path: "/personal/personaldata",
        name: "personaldata",
        component: () =>
          import("../views/personal/components/personaldata.vue"),
      },
      {
        path: "/personal/order",
        name: "order",
        component: () => import("../views/personal/components/order.vue"),
      },
      {
        path: "/personal/coupon",
        name: "coupon",
        component: () => import("../views/personal/components/coupon.vue"),
      },
    ],
  },
  {
    path: "/prolist",
    name: "prolist",
    component: () => import("../views/prolist/index.vue"),
  },
  {
    path: "/buy",
    name: "buy",
    component: () => import("../views/buy/index.vue"),
  },
  {
    path: "/register",
    name: "register",
    component: () => import("../views/register/index.vue"),
  },
  {
    path: "/issue",
    name: "issue",
    component: () => import("../views/issue/index.vue"),
  },
  {
    path: "/success",
    name: "success",
    component: () => import("../views/success/index.vue"),
  },
  {
    path: "/fail",
    name: "fail",
    component: () => import("../views/fail/index.vue"),
  },
  {
    path: "/forgetpwd",
    name: "forgetpwd",
    component: () => import("../views/forgetpwd/index.vue"),
  },
  {
    path: "/wechat",
    name: "wechat",
    component: () => import("../views/wechat/index.vue"),
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

const originalPush = VueRouter.prototype.push;

VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch((err) => err);
};

export default router;
