import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    // 用户是否登录
    islogin: false,
    // 用户名
    user: {},
    couponList: [],
    submitOrderParam: {},
    orderParam: {},
  },
  getters: {
    getOrderParam: (state) => state.submitOrderParam,
  },
  mutations: {
    changeState(state) {
      state.islogin = true;
    },
    setUser(state, user) {
      state.user = user;
    },
    setCouponList(state, couponList) {
      state.couponList = couponList;
    },
    submitOrderParam(state, submitOrderParam) {
      state.submitOrderParam = submitOrderParam;
    },
    setCouponId(state, couponIdList) {
      state.submitOrderParam.couponPeopleIdList = couponIdList;
    },
    setOrder(state, orderParam) {
      state.orderParam = orderParam;
    },
  },
  actions: {},
  modules: {},
});
