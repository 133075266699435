<template>
  <div>
    <!-- <div v-if="!isPc" style="text-align: center">
      <a ref="tt" id="rrr" class="btn189273892173918278921" :href="href" target="_parent">访问微信小程序</a>
    </div> -->
    <div class="header" id="app">
      <router-view />
    </div>
  </div>
</template>

<script>
export default {
  name: "App",
  mounted() {
    if (this._isMobile()) {
      this.$router.replace("/wechat");
    } else {
      this.$router.replace("/");
    }
  },
  methods: {
    _isMobile() {
      let flag = navigator.userAgent.match(
        /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
      );
      return flag;
    },
  },
};
</script>

<style>
@import "./assets/static/template/Home/Zkeys/PC/Static/css/common/module/sign/index.css";
@import "./assets/static/template/Home/Zkeys/PC/Static/css/common/common/common.css";
@import "./assets/static/template/Home/Zkeys/PC/Static/statics/css/public/front.css";
@import "./assets/static/template/Home/Zkeys/PC/Static/statics/css/front/style.css";
@import "./assets/static/template/Home/Zkeys/PC/Static/statics/css/front/index.css";
</style>

<style scoped></style>
